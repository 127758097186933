import {
    TechRadarApi,
    TechRadarLoaderResponse,
} from '@backstage/plugin-tech-radar';
import { ConfigReader } from '@backstage/core-app-api';
import { configuration } from '../config';

export class TechRadarClient implements TechRadarApi {
    async load(id: string | undefined): Promise<TechRadarLoaderResponse> {
        // if needed id prop can be used to fetch the correct data
        // For example, this converts the timeline dates into date objects
        const config = new ConfigReader(configuration);
        const data: any = await fetch(`${config.get('backend.baseUrl')}/api/universal-url-reader/read?url=https://github.com/${config.get('github.owner')}/${config.get('github.repo')}/blob/main/radar/${id}.json`).then(res => res.json());
        return {
            ...data,
            entries: data.entries.map((entry: { timeline: any[]; }) => ({
                ...entry,
                timeline: entry.timeline.map(timeline => ({
                    ...timeline,
                    date: new Date(timeline.date),
                })),
            })),
        };
    }
}