import React from 'react';
import { unmountComponentAtNode, render } from 'react-dom';
import { CodeGenerator } from '@idb-dab/exp-code-generator';
import { KubeclarityApp } from '@idb-dab/kubeclarity-app';
import { Simulator } from '@idb-dab/dab-simulator';
import * as icons from '@material-ui/icons';
import { FaCode } from "react-icons/fa6";

export const ExternalAppsConfig = {
    SIMULATOR: {
        app: "SIMULATOR",
        path: "/simulator",
        icon: icons.DeveloperMode,
        code: () => {
            import("@idb-dab/dab-simulator/dist/style.css");
            unmountComponentAtNode(document.getElementById('root')!);
            render(
                <React.StrictMode>
                    <Simulator />
                </React.StrictMode>,
                document.getElementById('root')!,
            );
        },
        target: '_blank',
        text: "Simulator"
    },
    CODE_GENERATOR: {
        app: "CODE_GENERATOR",
        path: "/code-generator",
        icon: FaCode,
        code: () => {
            import("@idb-dab/exp-code-generator/dist/style.css");
            unmountComponentAtNode(document.getElementById('root')!);
            render(
                <React.StrictMode>
                    <CodeGenerator />
                </React.StrictMode>,
                document.getElementById('root')!,
            );
        },
        target: '_blank',
        text: "Code Generator"
    },
    KUBECLARITY: {
        app: "KUBECLARITY",
        path: "/kubeclarity",
        icon: icons.Policy,
        code: () => {
            import("@idb-dab/kubeclarity-app/dist/style.css");
            unmountComponentAtNode(document.getElementById('root')!);
            render(
                <React.StrictMode>
                    <KubeclarityApp />
                </React.StrictMode>,
                document.getElementById('root')!,
            );
        },
        target: '_blank',
        text: "Kubeclarity"
    }
}